import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, of, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Template } from 'src/app/shared/models/Template';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    private templateUrl = '';
    private createTemplateUrl = 'conversationmessagetemplate';
    private editTemplateUrl = 'conversationmessagetemplate';
    private deactivateTemplateUrl = 'conversationmessagetemplate';
    private reactivateTemplateUrl = 'conversationmessagetemplate';
    shouldDisplayCreationMessage: boolean;

    constructor(private http: HttpClient) {
        this.templateUrl = environment.restapi.concat(this.templateUrl);
        this.createTemplateUrl = environment.restapi.concat(this.createTemplateUrl);
        this.editTemplateUrl = environment.restapi.concat(this.editTemplateUrl);
        this.deactivateTemplateUrl = environment.restapi.concat(this.deactivateTemplateUrl);
        this.reactivateTemplateUrl = environment.restapi.concat(this.reactivateTemplateUrl);
        this.shouldDisplayCreationMessage = false;
    }

    public get getShouldDisplayCreationMessage(): boolean {
        return this.shouldDisplayCreationMessage;
    }

    setShouldDisplayCreationMessage(value: boolean) {
        this.shouldDisplayCreationMessage = value;
    }

    getAllForUser(clinicEmrUid: string, userEmrUid: string): Observable<Template[]> {
        return this.http.get<Template[]>(`${this.templateUrl}clinics/${clinicEmrUid}/users/${userEmrUid}/conversationmessagetemplate`);
    }

    getByUid(templateUid: string): Observable<Template> {
        return this.http.get<Template>(`${this.templateUrl}conversationmessagetemplate/uid/${templateUid}`);
    }

    saveTemplate(clinicEmrUid: string,
                 userEmrUid: string,
                 name: string,
                 subject: string,
                 text: string,
                 link: string,
                 documentFilename: string,
                 documentFile,
                 voiceMessageFilename: string,
                 voiceMessage): Observable<any> {
        const params: FormData = new FormData();
        let linkHTML;

        if (link) {
            linkHTML = '<p>&nbsp;</p><p>' + link + '</p>';
        }

        params.append('name', name);
        params.append('subject', subject);
        params.append('text', text + (linkHTML ? linkHTML : ''));
        params.append('clinicEmrId', clinicEmrUid);
        params.append('userEmrId', userEmrUid);

        if (documentFile) {
            params.append('documentFilename', documentFilename + '-' + moment().format('YYYY-MM-DD-HHmm'));
            params.append('documentFile', documentFile);
        }

        if (voiceMessage) {
            params.append('voiceMessageFilename', voiceMessageFilename + '-' + moment().format('YYYY-MM-DD-HHmm') + '.wav');
            params.append('voiceMessage', voiceMessage);
        }
        
        return this.http.post<any>(`${this.createTemplateUrl}`, params).pipe(
            catchError(err => {
                return throwError(err);
            })
        );
    }

    saveExistingTemplate(templateId: string,
                         clinicEmrUid: string,
                         userEmrUid: string,
                         name: string,
                         subject: string,
                         text: string,
                         link: string,
                         documentFilename: string,
                         documentFile,
                         voiceMessageFilename: string,
                         voiceMessage,
                         mustRemoveDocument: boolean,
                         mustRemoveAudio: boolean): Observable<any> {
        const params: FormData = new FormData();
        let linkHTML;

        if (link) {
            linkHTML = '<p>&nbsp;</p><p>' + link + '</p>';
        }

        params.append('name', name);
        params.append('subject', subject);
        params.append('text', text + (linkHTML ? linkHTML : ''));
        params.append('clinicEmrId', clinicEmrUid);
        params.append('userEmrId', userEmrUid);

        if (!mustRemoveDocument && documentFile) {
            params.append('documentFilename', documentFilename);
            params.append('documentFile', documentFile);
        } else if (mustRemoveDocument) {
            params.append('documentFilename', null);
            params.append('documentFile', null);
        }

        if (!mustRemoveAudio && voiceMessage) {
            params.append('voiceMessageFilename', voiceMessageFilename + '-' + moment().format('YYYY-MM-DD-HHmm') + '.wav');
            params.append('voiceMessage', voiceMessage);
        } else if (mustRemoveAudio) {
            params.append('voiceMessageFilename', null);
            params.append('voiceMessage', null);
        }
        
        params.append('mustRemoveDocument', mustRemoveDocument ? 'true' : 'false');
        params.append('mustRemoveAudio', mustRemoveAudio ? 'true' : 'false');

        return this.http.put<any>(`${this.createTemplateUrl}/${templateId}`, params).pipe(
            catchError(err => {
                return throwError(err);
            })
        );
    }

    editName(templateUid: string, name: string): Observable<any> {
        const params = new HttpParams()
            .set('name', name);

        return this.http.put<any>(`${this.editTemplateUrl}/${templateUid}`, params);
    }

    editSubject(templateUid: string, subject: string): Observable<any> {
        const params = new HttpParams()
            .set('subject', subject);

        return this.http.put<any>(`${this.editTemplateUrl}/${templateUid}`, params);
    }

    editText(templateUid: string, text: string): Observable<any> {
        const params = new HttpParams()
            .set('text', text);

        return this.http.put<any>(`${this.editTemplateUrl}/${templateUid}`, params);
    }

    deactivateTemplate(templateUid: string): Observable<any> {
        return this.http.put(`${this.deactivateTemplateUrl}/${templateUid}/deactivate`, {});
    }
}
