import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Patient } from '../models/Patient';
import { environment } from 'src/environments/environment';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    private patientUIDUrl = 'kin/clinics/{clinicId}/patients/{patientEmrID}';
    private patientUrl = 'kin/clinics/{clinicId}/patient';

    constructor(private http: HttpClient) {
        this.patientUIDUrl = environment.restapi.concat(this.patientUIDUrl);
        this.patientUrl = environment.restapi.concat(this.patientUrl);
    }

    getByUID(clinicId: string, uid: string): Observable<Patient> {
        return this.http.get<Patient>(`${this.patientUIDUrl.replace('{clinicId}', clinicId).replace('{patientEmrID}', uid)}`);
    }

    getByHIN(clinicId: string, hin: string): Observable<Patient> {
        return this.http.get<Patient>(`${this.patientUrl}`.replace('{clinicId}', clinicId), {
            params: {
                hin: hin.toUpperCase()
            }
        });
    }

    getByFirstNameLastNameBirthdayGender(clinicId: string, firstName: string, lastName: string, birthday: string, gender: string): Observable<Patient> {
        return this.http.get<Patient>(`${this.patientUrl}`.replace('{clinicId}', clinicId), {
            params: {
                firstName: firstName,
                lastName: lastName,
                birthday: birthday,
                gender: gender.toLowerCase()
            }
        })
        .pipe(
            catchError(err => Observable.throw(err))
        );
    }
}
